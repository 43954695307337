import React, { useContext, useEffect, useState } from 'react'
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import { Default_Page_Limit } from '../../../../constants/defaultValues';
import { HeaderHeading } from '../../../../Context';
import constant from '../../../../constants/constant';
import service from '../../../../api/services';
import { getParams } from '../../../../constants/utils';
import { useSearchParams } from 'react-router-dom';

const { ApproveBarcode } = organisms
const Index = () => {
    const [searchParams] = useSearchParams();
    const [typeValue, setTypeValue] = useState<any>(getParams('approve-barcode-type') == constant.BRAND_CATEGORY[0].value)
    const [barcodeStatusList, setBarcodeStatus] = useState<string>()
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [search, setSearch] = useState('');
    const [pageCount, setPageCount] = useState<number>(1);
    const [limit, setLimit] = useState(Default_Page_Limit);
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [allBarcode, setAllBarcode] = useState<any>([]);
    const [storeData, setStoreData] = useState([]);
    const [storeId, setStoreId] = useState<any>([])

    useEffect(() => {
        setTypeValue(getParams('approve-barcode-type') == constant.BRAND_CATEGORY[0].value)
    }, [searchParams])


    const getBarcodeStatus = async () => {
        const data = {
            search: search,
            page: pageCount,
            limit: limit,
            barcode_status: constant?.BARCODE_STATUS?.APPROVED,
            is_recognised: typeValue,
        }
        try {
            const response:any = await service.barcode.view(data);
            setBarcodeStatus(response.data)
            const combinedArray = [...allBarcode, ...response.data.data];
            const uniqueIds = new Set<number>();

            // Filter the combined array to include only unique entries based on ID
            const uniqueArray = combinedArray.filter((item) => {
                if (!uniqueIds.has(item.id)) {
                    uniqueIds.add(item.id);
                    return true;
                }
                return false;
            });
            // Update the state with the unique array
            setAllBarcode(uniqueArray);
        } catch (error) {
            console.log(error)
        }
    }

    // useEffect(() => {
    //     setHeader("Inventory - Approve Barcode")
    //     getBarcodeStatus()
    // }, [pageCount,typeValue])

    useEffect(() => {
        setHeader("Inventory - Approve Barcode")
    
        if (initialLoading || pageCount === 1) {
            getBarcodeStatus();
        }
    
        if (limit || search || storeId) {
            setPageCount(1);
            setInitialLoading(true);
        }
    }, [pageCount, typeValue, limit, search, storeId, initialLoading]);
    return (
        <Wrapper>
            <ApproveBarcode
              barcodeStatusList={barcodeStatusList}
              setSearch={setSearch}
              setLimitPerPage={setLimit}
              setCurrentPage={setPageCount}
              getBarcodeStatus={getBarcodeStatus}
              currentPage={pageCount}
              limit={limit}
              allBarcode={allBarcode}
              storeData={storeData}
              handleChange={(value:any)=>setStoreId(value)}
              isRecognized={typeValue == constant.BRAND_CATEGORY[0]?.value}
            />
        </Wrapper>
    )
}

export default Index