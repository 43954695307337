import React, { useState, useRef, useEffect } from "react";
import atoms from "../../../atoms";
import molecules from "../../../molecules";
import { MyComponentProps } from "./interface";
const { Search } = atoms
const { PurchaseCard, BillingSummary, SearchTab } = molecules

const Index: React.FC<MyComponentProps> = ({ handleSearch, productList, handleSearchSelect, productCartData, removeItem, checkout, findLandingDiscount }) => {
    const [isOpen, setIsOpen] = useState(false);
    const currentRef: any = useRef()
    const [searchVal, setSearchVal] = useState("")
    const searchTabContainer = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event: any) => {
        if (searchTabContainer.current && !searchTabContainer.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const searchCallBack = (search: string) => {
        setSearchVal(search)
        handleSearch(search)
    }

    return (
        <>
            <div className="col-12 col-lg-8 m-0">
                <div className='checkout-card hide-scroll'>
                    <h2 className='mb-0 fs-16 fs-lg-18 fw-600'>Cart ({productCartData?.data?.length} items)</h2>

                    <div className='mt-2 mt-lg-4' ref={searchTabContainer}>
                        <div className="col-12 position-relative" onClick={() => setIsOpen(!isOpen)} >
                            <Search refs={currentRef} setIsOpen={setIsOpen} searchCallBack={searchCallBack} placeholder='Enter Model Number' className='w-100 fs-16' heading="Search Product" />
                            {searchVal && isOpen && <SearchTab data={productList.data} searchCallBack={(searchQuery: any) => handleSearchSelect(searchQuery.id)} setSearchVal={setSearchVal} />}
                        </div>
                    </div>
                    {productCartData?.data?.length > 0 && productCartData?.data?.map((item: any, index: number) => {
                        return (
                            <PurchaseCard
                                productCartData={item}
                                handleSearchSelect={(searchSelect: string) => handleSearchSelect(searchSelect)}
                                removeItem={(removeId: string) => removeItem(removeId)}
                            />
                        )
                    })}
                </div>
            </div>
            <div className="col-12 col-lg-4 mt-lg-0 mt-4">
                <div className="order-cart-billing-container hide-scroll">
                    <BillingSummary
                        productCartData={productCartData}
                        findLandingDiscount={(product: any, store: any) => findLandingDiscount(product, store)}
                        checkout={(product: any) => checkout(product)} />
                </div>
            </div>
        </>
    )
}

export default Index