import React, { useState, useEffect } from 'react'
import atoms from '../../atoms'
import { CiFilter } from "react-icons/ci"
import moment from 'moment'
import constant from '../../../constants/constant'
import { RxCross2 } from "react-icons/rx";
import { inventoryProps, pageIndex } from './interface'
import { findConstantLabel, getUniqueObj, numberWithCommas, convertJsonToExcel } from "../../../constants/utils"
import { useNavigate } from 'react-router'

const { Input, Select, Button, Table, Pagination } = atoms

const Index: React.FC<inventoryProps> = ({ brandData, searchdata, noDataText, setSearchdata, handleSubmit, isRecognized,listingData, setLimitPerPage, limit, isOpen, setIsOpen }) => {

    const navigate = useNavigate()
    const [displayedData, setDisplayedData] = useState([]);
    const columns = [
        { name: 'Barcode', selector: (row: any) => row?.bar_code, },
        { name: 'Brand Name', selector: (row: any) => row?.product?.brand?.brand_name ? row?.product?.brand?.brand_name : " ", },
        { name: 'Product Id', selector: (row: any) => row?.product?.product_id_display, },
        {
            name: 'Product Cover Photo',
            cell: (row: any) => {
                const style = {
                    height: "70%",
                    width: "50%",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    // backgroundPosition: 'center',
                    backgroundImage: `url(${row?.product?.images[0]})`
                }
                return <div style={style}></div>;
            },
        },
        { name: 'Model Number', selector: (row: any) => row?.product.modal_number, },
        { name: 'SRP', selector: (row: any) => `₹${numberWithCommas(parseFloat(Number(row?.product.product_srp)?.toFixed(2)))}`, },
        {
            name: 'Landing Cost', selector: (row: any, index: number) => row?.product?.product_type_id &&
                `₹${numberWithCommas(parseFloat(((getUniqueObj(row?.product?.brand?.product_types, "product_type_id", row?.product?.product_type_id)?.specx_landing_cost) * 0.01 * Number(row?.product?.product_srp)).toFixed(2)))}`
        },
        { name: 'Inventory Condition', selector: (row: any) => findConstantLabel(constant?.INVENTORY_ITEM_CONDITION, row?.item_condition) || '-', },
        { name: 'Created Date', selector: (row: any) => moment(row?.invoice.supplier_invoice_date).format('DD/MM/YYYY'), },
    ];

    const handlechange = (name: string, value: any) => {
        setSearchdata({ ...searchdata, [name]: value })
    }
    const handleCloseFilter = () => {
        setIsOpen(!isOpen)
    }

    const [newCurrentPage, setNewCurrentPage] = useState<number>(1);
    const [pageIndex, setPageIndex] = useState<pageIndex>({
        tableStartIndex: 0,
        tableEndIndex: 0
    })
    const totalPages = Math.ceil(listingData?.length / limit);
    useEffect(() => {
        const startIndex = (newCurrentPage - 1) * limit;
        const endIndex = startIndex + limit;
        const slicedData: any = listingData?.slice(startIndex, endIndex);
        setPageIndex({ ...pageIndex, tableStartIndex: startIndex, tableEndIndex: endIndex })
        setDisplayedData(slicedData)
    }, [newCurrentPage, listingData, limit]);

    useEffect(() => {
        setNewCurrentPage(1)
    }, [limit])

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setNewCurrentPage(newPage);
        }
    };

    const myStyle = {
        height: "45px",
        margin: 0,
        background: isOpen && "#05B7E4",
        color: isOpen && "#fff"
    };

    const handleDownloadExcel = () => {

        const excelData = listingData.map((item: any) => {
            const rowData: any = {};
            columns.forEach((column: any) => {
                if (column.name != "Product Cover Photo") {
                    rowData[column.name] = column.selector(item);
                }
            });
            return rowData;
        });

        convertJsonToExcel(excelData, "Inventory_excel")
    }

    return (
        <>
            <div className='d-flex justify-content-end gap-2'>
                <Button className='d-flex align-items-center border button-transparent rounded' style={myStyle} onClick={handleCloseFilter}>
                    <div className='fs-18 me-2 '>
                        <CiFilter className='fw-600' />
                    </div>
                    <div className='fs-16 fw-400'>Filter</div>
                </Button>
                <Button className='button-transparent border' name='Download Excel' onClick={() => handleDownloadExcel()} style={myStyle} />
                <Button name='Add Inventory' onClick={() => navigate(`/inventory/add?isRecognized=${isRecognized}`)} style={myStyle} />
            </div>

            {isOpen &&
                <div className='overlay'>
                    <div className='p-3 mt-3 side-filter' style={{ background: window.innerWidth < 768 ? "" : "transparent", border: "1px solid #BFBFBF30" }}>
                        {window.innerWidth < 768 && <div className="position-absolute py-1 px-2"
                            style={{ top: '0', right: '0', backgroundColor: '#222', borderRadius: '0px 4px 0px 8px', cursor: 'pointer' }}
                            onClick={() => handleCloseFilter()}>
                            <RxCross2 style={{ color: '#fff' }} />
                        </div>}
                        <div className='inventory-filter-scroll'>
                            <div className="row">
                                <div className="col-12 col-md-3 mb-md-3 mb-2">
                                    <Select className={"w-100"} options={brandData} value={searchdata.brand}
                                        onChange={(e: any) => handlechange("brand", e)} placeholder={"Select"}
                                        label={"Select Brand"}
                                        isMulti={true} />
                                </div>
                                <div className="col-12 col-md-3 mb-md-3 mb-2">
                                    <Input type={"text"} name="barcode" onChange={(e: any) => handlechange("barcode", e.target.value.trim())}
                                        value={searchdata.barcode} placeholder={"Enter Barcode"} label={"Barcode"} /*errors={errors.barcode}*/ />
                                </div>
                                <div className="col-12 col-md-3 mb-md-3 mb-2">
                                    <Input type={"text"} name="product_id" onChange={(e: any) => handlechange("product_id", e.target.value.trim())}
                                        value={searchdata.product_id} placeholder={"Enter Product Id"} label={"Product Id"} /*errors={'brandError?.brand_name'} */ />
                                </div>
                                <div className="col-12 col-md-3 mb-md-3 mb-2">
                                    <Input type={"text"} name="modal_number" onChange={(e: any) => handlechange("modal_number", e.target.value.trim())}
                                        value={searchdata.modal_number} placeholder={"Enter Model No"} label={"Model No"} /*errors={'brandError?.brand_name'} */ />
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-12 col-md-6'>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 mb-md-0 mb-2">
                                            <Input type={"text"} name="minPrice" onChange={(e: any) => handlechange("minPrice", e.target.value.trim())}
                                                value={searchdata.minPrice} placeholder={"Enter Min Price"} label={"Min Price"} /*errors={'brandError?.brand_name'} */ />
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            <Input type={"text"} name="maxPrice" onChange={(e: any) => handlechange("maxPrice", e.target.value.trim())}
                                                value={searchdata.maxPrice} placeholder={"Enter Max Price"} label={"Max Price"} /*errors={'brandError?.brand_name'} */ />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 mt-md-3 mt-2 d-flex justify-content-md-end justify-content-center'>
                                    <Button name='Search'
                                        className='mt-3 mb-0'
                                        isDisabled={!searchdata?.modal_number && !searchdata.barcode && !(searchdata?.store.length > 0) && !searchdata?.product_id && (!searchdata.maxPrice || !searchdata.minPrice) && !(searchdata?.brand.length > 0)}
                                        type={"submit"} onClick={() => handleSubmit()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="row">
                <div className="col">
                    {noDataText && <Table columns={columns} AdditionalClassName="table-height-50" data={displayedData} />}
                </div>
                <div className={`${isOpen && 'position-relative'}`} style={{ top: "12px" }}>
                    <Pagination
                        totalCount={listingData?.length}
                        currentPage={newCurrentPage}
                        totalPages={totalPages}
                        onChangePage={handlePageChange}
                        setLimit={(e: number) => setLimitPerPage(e)}
                        limit={limit}
                    />
                </div>
            </div>


        </>
    )
}

export default Index