import React from 'react';
import PlaceHolderImage from '../../../assets/Images/placeholderimg.png'
import { MyCustomImageInterface } from './interface'

const CustomImages = ({ src, imageHeight, className, height }: MyCustomImageInterface) => {
    const customStyle = {
        height: "75%",
        width: "100%",
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${src ? src : PlaceHolderImage})`,
        minHeight: `${!height && (imageHeight ? imageHeight : '150px')}`
    };
    return (
        <div className={`${className}`} style={customStyle}></div>
    );
}

export default CustomImages;
