import React, { useState } from 'react'
import BrandPlaceHolder from '../../../assets/Images/placeholderimg.png';
import atoms from '../../atoms';
import { findConstantLabel, numberWithCommas } from '../../../constants/utils';
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosAddCircleOutline } from "react-icons/io";
import constant from '../../../constants/constant';
import { IndexProps } from './interface';

const { Image } = atoms

const Index = ({ productCartData, handleSearchSelect, removeItem }: IndexProps) => {
    const [showProductDetail, setShowProductDetail] = useState<boolean>(false)

    const repeatItem = (id: string) => {
        handleSearchSelect(id)
    }

    return (

        <>
            {productCartData ?
                <>
                    <div className='checkout-product-card mt-2 mt-md-4'>
                        <div>
                            <div className='row'>
                                <div className='d-none d-md-flex col-md-8 col-lg-6 flex-wrap align-items-center gap-lg-4 '>
                                    <h2 className='mb-0 fs-lg-14 fs-12 fw-600'>{productCartData?.order?.order_id_display ? "Order ID" : "Product ID"}:-</h2>
                                    <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                                        <h2 className='mb-0 fs-12 fw-400'>{productCartData?.order?.order_id_display ? productCartData?.order?.order_id_display : productCartData?.product?.product_id_display}</h2>
                                        <Image src={'Copy'} alt="" className='img-fluid' />
                                    </div>
                                </div>

                                {(!productCartData?.order?.order_id_display && !productCartData?.warranty_claim_id) && <div className='col-md-4 col-lg-6 d-none d-md-flex justify-content-end mt-lg-0 mt-md-0 mt-3 flex-lg-wrap align-items-center gap-3'>
                                    <h2 className='mb-0 fs-14 fw-500 text-decoration-underline cursor-pointer' onClick={() => { removeItem(productCartData?.id) }} >Remove</h2>
                                    <h2 className='mb-0 fs-14 fw-500 text-decoration-underline cursor-pointer' onClick={() => { repeatItem(productCartData?.product?.id) }} >Repeat</h2>
                                </div>}

                                <div className='d-md-none d-flex justify-content-between w-100 align-items-center'>
                                    <div>
                                        <h2 className='mb-0 fs-16 fw-600'>{productCartData?.product?.brand?.brand_name}</h2>
                                    </div>
                                    <div className='d-flex gap-2 text-sm-start text-md-start text-lg-end mt-1 mt-md-0 align-items-center'>
                                        <p className='mb-0 fs-md-16 fs-12 fw-500 dark-gray text-decoration-line-through'>₹{numberWithCommas(parseFloat(Number(productCartData?.product?.product_srp)?.toFixed(2)))}(SRP)</p>
                                        <p className='mb-0 fs-md-18 fs-14 fw-600 dark-gray'>₹{numberWithCommas(parseFloat(Number(productCartData?.landing_cost).toFixed(2)))}(L.Cost)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-5 col-lg-3 mt-2 mt-md-4">
                                    <div className='sales-product-image' style={{ overflow: "hidden" }}>
                                        <div className=''>
                                            <div style={{ backgroundImage: `url(${productCartData?.product?.images[0] || BrandPlaceHolder})` }}
                                                className='img-fluid product-image ' />
                                        </div>
                                        <div className='d-md-none row'>
                                            <div className='col-6 d-flex justify-content-center border p-1' onClick={() => removeItem(productCartData?.id)}>
                                                <RiDeleteBin6Line className='fs-18' style={{ color: "#fb3f3fe6" }} />
                                            </div>
                                            <div className='col-6 d-flex justify-content-center border p-1' onClick={() => repeatItem(productCartData?.product?.id)}>
                                                <IoIosAddCircleOutline className='fs-18' />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div style={{ backgroundImage: `url(${productCartData?.product?.images[0] || BrandPlaceHolder})` }}
                                        className='img-fluid product-image ' /> */}
                                </div>

                                <div className="col-7 col-lg-9 mt-2 mt-md-4 px-0 px-md-1">
                                    <div className='d-flex flex-wrap justify-content-between align-items-end '>
                                        <div className='d-none d-md-block w-lg-75'>
                                            <h2 className='d-none d-md-block mb-0 fs-16 fw-600 dark-gray'>{productCartData?.product?.brand?.brand_name}</h2>
                                            <div className="d-flex flex-wrap gap-1 gap-lg-3 align-items-center my-2 ">
                                                <h2 className='mb-lg-0 mb-0 fs-13 fw-400 dark-gray' style={{ minWidth: "140px" }}>Model Number: <span className='mb-0 fs-13 fw-600'>{productCartData?.product?.modal_number}</span></h2>
                                                <h2 className='mb-lg-0 mb-1 fs-13 fw-400 dark-gray' style={{ minWidth: "140px" }}>Warrantee: <span className='mb-0 fs-13 fw-600'>{findConstantLabel(constant.PRODUCT_WARRANTY, productCartData?.product?.warranty)} </span></h2>
                                                <h2 className='mb-lg-0 mb-1 fs-13 fw-400 dark-gray' style={{ minWidth: "85px" }}>Polarized: <span className='mb-0 fs-13 fw-600'>{productCartData?.product?.isPolarised == 1 ? 'Yes' : 'No'}</span></h2>
                                            </div>

                                            <div className="d-flex gap-3 flex-wrap align-items-center mb-2">
                                                <h2 className='mb-lg-0 mb-0 fs-13 fw-400 dark-gray'>Colour Code: <span className='mb-0 fs-13 fw-600'>{productCartData?.product?.brand_color_code}  </span></h2>
                                                <h2 className='mb-lg-0 mb-0 fs-13 fw-400 dark-gray'>With Case: <span className='mb-0 fs-13 fw-600'>{productCartData?.product?.product_with_case ? "Yes" : "No"}</span></h2>
                                                <h2 className='mb-lg-0 mb-1 fs-13 fw-400 dark-gray'>Size: <span className='mb-0 fs-13 fw-600'>{productCartData?.product?.glass_size}</span></h2>
                                            </div>
                                            <div className="d-flex gap-3 align-items-center mb-2">
                                                {<h2 className='mb-0 fs-13 fw-400 dark-gray'>Features:
                                                    {productCartData?.product?.features?.length > 0 && productCartData?.product?.features?.map((item, index: number) => {
                                                        return (
                                                            <span className='mb-0 fs-13 fw-600'>
                                                                {item ? ` ${findConstantLabel(constant.PRODUCT_FEATURES, item)} ${index < (productCartData?.product?.features?.length - 1) ? "," : ""}` : ""}
                                                            </span>
                                                        )
                                                    })}
                                                </h2>}
                                            </div>
                                            <p className='mb-0 fs-14 fw-400 dark-gray mt-3'>{productCartData?.product?.description}</p>
                                        </div>
                                        <div className='d-none d-md-flex text-sm-start text-lg-start text-lg-end mt-3 mt-lg-0'>
                                            <p className='mb-0 fs-14 fw-500 dark-gray text-decoration-line-through'>₹{numberWithCommas(parseFloat(Number(productCartData?.product?.product_srp)?.toFixed(2)))}(SRP)</p>
                                            <p className='mb-0 fs-16 fw-600 dark-gray'>₹{numberWithCommas(parseFloat(Number(productCartData?.landing_cost).toFixed(2)))}(L.Cost)</p>
                                        </div>

                                        {/* for mobile view */}
                                        <div className='d-md-none w-100'>
                                            <div className="row align-items-center">
                                                <div className='col-6 pe-0'>
                                                    <h2 className='mb-lg-0 mb-1 fs-10 fw-400 dark-gray pe-0'>Model Number: <span className='mb-0 fs-10 fw-600'>{productCartData?.product?.modal_number}</span></h2>
                                                </div>
                                                <div className='col-6 pe-0'>
                                                    <h2 className='mb-lg-0 mb-1 fs-10 fw-400 dark-gray'>Size: <span className='mb-0 fs-10 fw-600'>{productCartData?.product?.glass_size}</span></h2>
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className='col-6'>
                                                    <h2 className='mb-1 mb-lg-0 fs-10 fw-400 dark-gray' style={{ minWidth: "140px" }}>Warrantee: <span className='mb-0 fs-10 fw-600'>{findConstantLabel(constant.PRODUCT_WARRANTY, productCartData?.product?.warranty)} </span></h2>
                                                </div>
                                                <div className='col-6'>
                                                    <h2 className='mb-1 mb-lg-0 fs-10 fw-400 dark-gray'>With Case: <span className='mb-0 fs-10 fw-600'>{productCartData?.product?.product_with_case ? "Yes" : "No"}</span></h2>
                                                </div>
                                            </div>
                                            {showProductDetail && <div>
                                                <div className="row align-items-center">
                                                    <div className='col-6'>
                                                        <h2 className='mb-1 mb-md-0 fs-10 fw-400 dark-gray' style={{ minWidth: "85px" }}>Polarized: <span className='mb-0 fs-10 fw-600'>{productCartData?.product?.isPolarised == 1 ? 'Yes' : 'No'}</span></h2>
                                                    </div>
                                                    <div className='col-6'>
                                                        <h2 className='mb-1 mb-md-0 fs-10 fw-400 dark-gray'>Colour Code: <span className='mb-0 fs-10 fw-600'>{productCartData?.product?.brand_color_code}  </span></h2>
                                                    </div>
                                                </div>
                                                <div>
                                                    {<h2 className='fs-10 fw-400 dark-gray'>Features:
                                                        {productCartData?.product?.features?.length > 0 && productCartData?.product?.features?.map((item: any, index: number) => {
                                                            return (
                                                                <span className='mb-0 fs-10 fw-600'>
                                                                    {item ? ` ${findConstantLabel(constant.PRODUCT_FEATURES, item)} ${index < (productCartData?.product?.features?.length - 1) ? "," : ""}` : ""}
                                                                </span>
                                                            )
                                                        })}
                                                    </h2>}
                                                </div>
                                                {productCartData?.product?.description && <div className='row'>
                                                    <p className='mb-0 fs-14 fw-400 dark-gray mt-1'>{productCartData?.product?.description}</p>
                                                </div>}


                                            </div>}
                                            <div className='d-md-none text-start'>
                                                <a className='fs-10' style={{ minWidth: "40px" }} onClick={() => setShowProductDetail(!showProductDetail)}>{!showProductDetail ? "see more" : "see less"}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> :
                <div className='text-center mt-4'>
                    <span className='fs-14 fw-400'>There are no records to display</span>
                </div>

            }
        </>

    )
}

export default Index