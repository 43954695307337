import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import atoms from '../../atoms'
import coverImage from '../../../assets/Images/coverImage.jpeg'
import constant from '../../../constants/constant'
import CopyIcon from '../../../assets/Images/copy-icon.svg'
import './index.scss'
import { RxCross2 } from "react-icons/rx";
import { BASE_URL } from '../../../constants/defaultValues'
import { findConstantLabel, getDropdownValue } from '../../../constants/utils'
import productDetail from '../../../api/services/product'
const { Button, Image } = atoms
const SidePopUp = ({ show, onHide, productDetail, showImage, updateVerificationStatus,isBrandedCategory }: any) => {
    const handleUpdateStatus = (status: number) => {
        updateVerificationStatus(productDetail?.id, status)
        onHide()
    }
    const pm = productDetail?.product_materials


    return (
        <Modal className="rounded-0"
            isOpen={show}
            wrapClassName="modal-right"
        >
            <ModalHeader className="d-flex border-0 position-relative" >
                {/* <div className="mt-4 fs-24 fw-600">{header}</div> */}
                <div className="mt-4 fs-24 fw-600 mb-0">Product Name</div>
                <div className="position-absolute py-1 px-2" style={{ top: '15%', right: '3%', backgroundColor: 'transparent', border: '1px solid #222', borderRadius: '50%', cursor: 'pointer' }} onClick={onHide}><RxCross2 style={{ color: '#222' }} /></div>
            </ModalHeader>
            <ModalBody className="pt-md-0">

                <>
                    <div className=' d-flex gap-3 align-items-center mb-4'>
                        <h2 className='mb-0 fs-18 fw-700 secondary-color '>Product ID:</h2>
                        <div className='d-flex w-auto justify-content-around align-items-center p-2 add-product-custom-div cursor-pointer' >
                            <h2 className='mb-0 fs-16 fw-600 secondary-color'>{productDetail?.product_id_display}</h2>
                            <div className='ms-2'>
                                <Image src={CopyIcon} onClick={() => navigator.clipboard.writeText(productDetail?.product_id_display)} />
                            </div>
                        </div>
                    </div>
                    <hr className="mt-3" style={{ border: '1px dashed #222', }} />
                    <div className="mt-4 fs-20 fw-600 secondary-color">Basic Details</div>
                    <div className='d-flex justify-content-between'>
                        <div className="d-flex mt-20 info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Brand :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.brand?.brand_name}</h2>
                        </div>
                        <div className="d-flex mt-20 info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Product Type :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.product_type?.name}</h2>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between'>
                        <div className="d-flex info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Model Number :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.modal_number}</h2>
                        </div>
                        <div className="d-flex  info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Brand Color Code :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.brand_color_code}</h2>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between'>
                        <div className="d-flex  info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Brand Color Name :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.brand_color_name}</h2>
                        </div>
                        <div className="d-flex  info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">SW Color Name:</h2>
                            <div>
                                {productDetail?.specx_colors && productDetail?.specx_colors?.map((color: any) =>
                                (<span className="mb-0 mx-1 fs-16 fw-400 secondary-color info-text">
                                    {color?.color_name}</span>)
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between'>
                        <div className="d-flex  info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">SW Lens Color :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.lens_colors && productDetail?.lens_colors.map((lens_colors: any) => (`${lens_colors?.color_name}, `))}</h2>

                            {/* <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.lens_colors?.[0]?.color_name}</h2> */}
                        </div>
                        <div className="d-flex info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Glass Size :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.glass_size}</h2>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className="d-flex  info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Temple Size :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.temple_size}</h2>
                        </div>
                        <div className="d-flex info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Bridge size :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.bridge_size ? productDetail?.bridge_size : " "}</h2>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className="d-flex info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Product Material :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.product_materials && productDetail?.product_materials.map((product_materials: any) => (`${product_materials?.name}, `))}</h2>

                            {/* <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.product_materials && productDetail?.product_materials[0]?.name}</h2> */}
                        </div>
                        <div className="d-flex info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Features :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.features && productDetail?.features.map((age: any) => findConstantLabel(constant.PRODUCT_FEATURES, age)).join(",")}</h2>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className="d-flex info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Rim Type :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.rim_type ? findConstantLabel(constant?.PRODUCT_RIM_TYPE, productDetail?.rim_type) : " "}</h2>
                        </div>
                        <div className="d-flex info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Product Shape :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.shapes && productDetail?.shapes.map((shapes: any) => (`${shapes?.name}, `))}</h2>
                            {/* <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.shapes  productDetail?.shapes[0]?.name}</h2> */}
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className="d-flex info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Gender:</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.gender && productDetail?.gender.map((gender: any) => findConstantLabel(constant.GENDER, gender)).join(",")}</h2>
                        </div>

                        <div className="d-flex info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text"> Product SRP :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.product_srp ? productDetail?.product_srp : " "}</h2>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between'>
                        <div className="d-flex info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Warranty :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.warranty ? findConstantLabel(constant?.PRODUCT_WARRANTY, productDetail?.warranty) : " "}</h2>
                        </div>
                        <div className="d-flex info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Product Weight :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.weight ? findConstantLabel(constant?.PRODUCT_WEIGHT, productDetail?.weight) : ""} </h2>
                            {/* <div className="col-1 text-end">{getNestedData(isViewData.product_details, "quantity")}</div> */}
                        </div>
                    </div>

                    <div className='d-flex justify-content-between'>
                        <div className="d-flex info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text"> Product With Case :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.product_with_case ? <p className='fs-16 fw-400 secondary-color info-text mx-2'>Yes</p> : <p className='fs-16 fw-400 secondary-color info-text mx-2'>No</p>}</h2>
                        </div>
                        <div className="d-flex info-style" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Polarized :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.isPolarised ? findConstantLabel(constant?.POLARIZED, productDetail?.isPolarised) : ""}</h2>
                        </div>
                    </div>

                    <div className="mt-4 fs-20 fw-600 secondary-color">Product Images</div>
                    {/* </div> */}
                    <div className='row'>
                        {productDetail?.images && productDetail?.images.map((productImage: any, index: number) => {
                            return (
                                <div className="d-flex flex-wrap gap-4 col-2">
                                    <div className="mt-3 info-cover-img position-relative" >
                                        <Image className="info-cover-img" src={productImage} onClick={() => showImage(productDetail?.images, index)} />
                                        {productDetail?.cover_index === index && <h2 className='fs-12 fw-500 position-absolute w-100 text-center cover-text-bg mb-0' style={{ bottom: '0', }}>Cover Image</h2>}
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="d-flex info-style mt-3" >
                        <h2 className="mb-0 fs-16 fw-600 secondary-color info-text"> Product Description :</h2>
                        <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{productDetail?.description}</h2>
                    </div>
                </>

                <hr className="mt-5" style={{ border: '1px dashed #222', }} />

            </ModalBody>


            <ModalFooter className="border-0 d-flex" style={{ flexWrap: 'inherit' }}>
                {
                    productDetail?.verification_status == constant.VERIFICATION_STATUS.PENDING &&  isBrandedCategory && 
                    <>
                        <Button className="fs-16 fw-500 reject-btn" onClick={() =>
                            handleUpdateStatus(constant.VERIFICATION_STATUS.REWORK)
                        }>
                            Reject
                        </Button>{" "}
                        <Button className="fs-16 fw-500 approve-btn" onClick={() =>
                            handleUpdateStatus(constant.VERIFICATION_STATUS.VERIFIED)
                        }>
                            Approve
                        </Button></>
                }
            </ModalFooter>
        </Modal>
    )
}

export default SidePopUp