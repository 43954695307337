import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import atoms from '../../atoms'
import './index.scss'
import { RxCross2 } from "react-icons/rx";

const { Image } = atoms
const BulkSidePopUp = (props: any) => {

    return (
        <Modal className="rounded-0"
            isOpen={props.show}
            wrapClassName="modal-right"
        >
            <ModalHeader className="d-flex border-0 position-relative" >
                <div className="mt-4 fs-24 fw-600 mb-0">Upload Image</div>
                <div className="position-absolute py-1 px-2" style={{ top: '15%', right: '3%', backgroundColor: 'transparent', border: '1px solid #222', borderRadius: '50%', cursor: 'pointer' }} onClick={props.onHide}><RxCross2 style={{ color: '#222' }} /></div>
            </ModalHeader>
            <ModalBody className="pt-md-0">
                <div className="d-flex row">
                    {props?.previewImage.length > 0 && props?.previewImage.map((image: any, index: any) => {
                        return (
                            <div className="photo_upload mb-2 m-3 col-2">
                                <div className="photo_preview cursor-pointer mb-2">
                                    <div
                                        id="imagePreview"
                                    >
                                        <Image src={`${image.imageUrl && image.imageUrl}`} alt='aaa' className={"img-fluid preview_image"} />

                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                <hr className="mt-5" style={{ border: '1px dashed #222', }} />

            </ModalBody>
        </Modal>
    )
}

export default BulkSidePopUp