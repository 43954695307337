const Inventory = {
    inventoryBrand:()=> 'inventory/invoice/brand-filters',
    inventoryItems:()=> 'inventory/invoice/items-search',
    invoiceById: (id: string) => `store/inventory/${id}`,
    inventory: () => 'store/inventory',
    inventoryInvoice:()=>'store/inventory',
    productSearch: ()=> `store/inventory/invoice/products`,
    supplier:()=>'inventory/invoice/stores/suppliers',
    verificationStatus: (id: string) => `store/invoice/${id}/verification-status`,
    deletePendingInventory:(id:string)=>`inventory/invoice/${id}`,
    invoiceDataCount: () => `inventory/invoice/count`,
}
export default Inventory