import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { barcode } = Dictionary

const BarcodeService = {
    view: async (payload:{}) => {
        return axios.post(BASE_URL + barcode?.view(), payload)
    }, 
    statusVerificationService: async (payload:{}) => {
        return axios.post(BASE_URL + barcode?.statusVerificationService(), payload)
    },
    statusChangeService: async (id:string)=>{
        return axios.put(BASE_URL + barcode.barcodeStatusById(id));
    },
}
export default BarcodeService
