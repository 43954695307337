import React, { useState, useEffect, useContext } from "react";
import organisms from "../../../components/organisms";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import service from "../../../api/services";
import { HeaderHeading } from "../../../Context";
import constant from "../../../constants/constant";
import { useSearchParams } from "react-router-dom";
import { getParams } from "../../../constants/utils";
import { constants } from "buffer";

const { Brand } = organisms
const Index = () => {
    // const url = window.location.href;
    // const unrecognized = url.split('/').pop() || '';
    const [searchParams] = useSearchParams();
    const [typeValue,setTypeValue] = useState<any>(getParams('brand-type'))
    const { setHeader }: any = useContext(HeaderHeading);
    const [storeBrandList, setStoreBrandList] = useState()
    useEffect(() => { 
        setTypeValue(getParams('brand-type'))
    }, [searchParams])

    const getStoreBrandList = async () => {
        try {
            const response = await service.brandService?.brandList({ brand_category: typeValue })
            if (response.status === 200) {
                setStoreBrandList(response?.data)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        setHeader("Brand")
        getStoreBrandList()
    }, [typeValue])

    return (
        <Wrapper>
            <Brand
                storeBrandList={storeBrandList}
                unrecognized={typeValue == constant.BRAND_CATEGORY[0].value}
            />
        </Wrapper>
    )
}

export default Index