import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import constant from "../../../../constants/constant"
import { Default_Page_Limit } from "../../../../constants/defaultValues"
import organisms from '../../../../components/organisms'
import { HeaderHeading } from '../../../../Context'
import { useSearchParams } from 'react-router-dom'
import { getParams } from '../../../../constants/utils'
import { useToasts } from 'react-toast-notifications'

const { PendingInventory } = organisms

const Index = () => {
  const { addToast } = useToasts();
  const [searchParams] = useSearchParams();
  const [typeValue, setTypeValue] = useState<any>(getParams('inventory-pending-type') == constant.BRAND_CATEGORY[0]?.value)
  const [initialLoading, setInitialLoading] = useState(false)
  const [transferRequestMoveOutList, setTransferRequestMoveOutList] = useState<any>();
  const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string | undefined>();
  const { setHeader }: any = useContext(HeaderHeading);

  useEffect(() => {
    setTypeValue(getParams('inventory-pending-type') == constant.BRAND_CATEGORY[0]?.value)
  }, [searchParams])

  const getInventory = async () => {
    try {
      const response = await service.inventory.view({
        "page": currentPage,
        "search": search,
        "limit": limitPerPage,
        "status": constant.VERIFICATION_STATUS.PENDING,
        is_recognised: typeValue
      })
      if (response.status === 200) {
        setTransferRequestMoveOutList(response?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setHeader("Inventory - Pending")
    if (initialLoading) {
      getInventory()
    }
    setInitialLoading(true)
  }, [currentPage,typeValue])

  useEffect(() => {
    setCurrentPage(1)
    if (currentPage == 1) {
      getInventory()
    }
  }, [limitPerPage, search])
  const handleUpdateStatus = async (id: any, status: any) => {
    try {
      let response = await service.inventory.updateStatusById(id, { "status": status });
      getInventory()
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = async (id: string) => {
    try {
        const response = await service.inventory.deletePendingInventory(id);
        if (response?.status === 200) {
          getInventory()
            addToast(response?.data?.message, {
                appearance: 'success',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    } catch (error: any) {
        console.log(error)
        addToast(error.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 3000,
        });
    }

}

  return (
    <Wrapper>
      <PendingInventory
        pendingInventoryData={transferRequestMoveOutList}
        setSearch={setSearch}
        limit={limitPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setLimitPerPage={setLimitPerPage}
        updateVerificationStatus={(id: string, status: number) => handleUpdateStatus(id, status)}
        isRecognized={typeValue == constant.BRAND_CATEGORY[0]?.value}
        handleDelete={(id: string) => handleDelete(id)}
      />
    </Wrapper>
  )
}

export default Index