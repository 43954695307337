import Dashboard from './Dashboard'
import ProductList from './Procurement/Product'
import ProductDetail from './ProductDetail'
import StaffAdd from './Staff/AddStaff'
import Staff from './Staff/index'
import OrderCart from './Procurement/OrderCart'
import OrderHistory from './Procurement/OrderHistory'
import OrderHistoryItems from './Procurement/ItemsDetail'
import OrderCancelled from './Procurement/OrderCancelled'
import OrderReceived from './Procurement/OrderReceived'
import TransferRequested from './TransferRequest/Requested'
import TransferRequestMoveOut from './TransferRequest/MovedOut'
import TransferRequestCancelled from './TransferRequest/Cancelled'
import Inventory from './Inventory'
import Transaction from './Accounts/Transaction'
import GeneralAccount from './Accounts/General-Account'
import InventoryReport from './Report/InventoryReport'
import ChangePassword from './ChangePassword'
import InventoryAudit from './InventoryAudit'
import Brand from './Brand'
import DailyActivityReport from './Report/DailyActivityReport'
import GSTOutputReport from './Report/GSTOutputReport'
import SalesReport from './Report/SalesReport'
import InventoryAdditionReport from "./Report/InventoryAdditionReport"
import InventoryDashboard from './Dashboard/inventory'
import OrderDashboard from './Dashboard/order'
import AccountsDashboard from './Dashboard/accounts'
import SalesReturn from './Report/SalesReturn'
import AddInventory from './Inventory/Addition'
import AddProduct from "./Product/AddProduct"
import BulkUpload from './Product/BulkUpload'
import Setting from "./Setting"
import AddBrand from "./Brand/AddBrand"
import ApproveInventory from "./Inventory/ApproveInventory"
import PendingInventory from "./Inventory/PendingInventory"
import UnrecognizedInventory from "./Inventory/Unrecognized"
import Product from './Product'
import Barcode from "./Barcode"
import ApproveBarcode from "./Barcode/ApproveBarcode"
const pages = {
  Dashboard,
  ProductList,
  ProductDetail,
  StaffAdd,
  Staff,
  OrderCart,
  OrderHistory,
  OrderReceived,
  OrderCancelled,
  OrderHistoryItems,
  TransferRequested,
  TransferRequestMoveOut,
  TransferRequestCancelled,
  Inventory,
  Transaction,
  GeneralAccount,
  InventoryReport,
  ChangePassword,
  InventoryAudit,
  Brand,
  DailyActivityReport,
  GSTOutputReport,
  SalesReport,
  InventoryAdditionReport,
  InventoryDashboard,
  OrderDashboard,
  AccountsDashboard,
  SalesReturn,
  AddInventory,
  AddProduct,
  Setting,
  AddBrand,
  BulkUpload,
  ApproveInventory,
  PendingInventory,
  UnrecognizedInventory,
  Product,
  Barcode,
  ApproveBarcode,
}
export default pages
