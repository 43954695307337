import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import organisms from "../../../components/organisms";
import service from "../../../api/services";
import { Default_Page_Limit, Default_Page_Number } from "../../../constants/defaultValues";
import { HeaderHeading } from '../../../Context'
import { BASE_URL } from "../../../constants/defaultValues"
import constant from "../../../constants/constant";
import { useSearchParams } from "react-router-dom";
import { getParams } from "../../../constants/utils";
import { constants } from "buffer";

const { ApproveProduct } = organisms

const Index = () => {
    const [searchParams] = useSearchParams();
    const [typeValue, setTypeValue] = useState<any>(getParams('product-type'))
    const [productData, setProductData] = useState<any>({})
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [productDetail, setProductDetail] = useState({});
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [search, setSearch] = useState('');
    const { setHeader, }: any = useContext(HeaderHeading);
    const [brand, setBrand] = useState<any>([])
    const [productType, setProductType] = useState<any>([])
    const [newProductData, setNewProductData] = useState<any>({})
    const [brandResponse, setBrandResponse] = useState([])

    useEffect(() => {
        setTypeValue(getParams('product-type'))
    }, [searchParams])


    const getDropdown = (response: []) => {
        let dropdown: any = []
        response && response.map((value: any) => {
            dropdown.push({
                "value": value.id || value.product_type.id,
                "label": value.name || value.brand_name || value.color_name || value.product_type.name,
                "code": value?.brand_code || value?.product_type_initials || value?.product_type?.product_type_initials
            }
            )
        })
        return dropdown
    }

    const getProductData = async () => {
        try {
            // let brandResponse = {}
            // let productTypeResponse ={}

            // setBrand(getDropdown(brandResponse?.data?.data))
            // setProductType(getDropdown(productTypeResponse?.data?.data))
            // setBrandResponse(brandResponse?.data?.data)
        } catch (error) {
            console.error(error)
        }
    }

    // const handleDownload = async () => {
    //     const brands = newProductData?.brand_id?.map((item: any) => item?.value)
    //     const product_types = newProductData?.product_type_id?.map((item: any) => item?.value)
    //     const payload = {
    //         brands: brands,
    //         product_types: product_types,
    //         modal_number: newProductData?.modal_number,
    //     }
    //     try {
    //         const response = await service.BulkEditService.download(payload)
    //         if (response.status === 200) {
    //             let path = response.data.data
    //             let fullPath = `${BASE_URL}${path}`
    //             let cleanPAth = fullPath.replace(/api\/\.\//, '')
    //             let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)
    //             // window.open(`${cleanPAth}`, '_blank')

    //             setTimeout(() => {
    //                 fetch(`${cleanPAth}`).then((response) => {
    //                     response.blob().then((blob) => {

    //                         // Creating new object of PDF file
    //                         const fileURL =
    //                             window.URL.createObjectURL(blob);

    //                         // Setting various property values
    //                         let alink = document.createElement("a");
    //                         alink.href = fileURL;
    //                         alink.download = `${fileName}`;
    //                         alink.click();
    //                     });
    //                 });
    //             }, 2000);

    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const getAllDetail = async () => {
        const brands = newProductData?.brand_id?.map((item: any) => item?.value)
        const product_types = newProductData?.product_type_id?.map((item: any) => item?.value)
        let NewBrands = JSON.stringify(brands)
        let NewProductTypes = JSON.stringify(product_types)
        try {
            let response = await service.product.view(
                {
                    "page": currentPage,
                    "search": search,
                    "limit": limitPerPage,
                    "status": constant.VERIFICATION_STATUS.VERIFIED,
                    "brands": NewBrands,
                    "product_types": NewProductTypes,
                    "modal_number": newProductData?.modal_number,
                    brand_category: typeValue
                }
            )
            if (response.status === 200) {
                setProductData(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getDetailById = async (id: string) => {
        try {
            let response = await service.product.getDetailById(id)
            if (response.status === 200)

                setProductDetail(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    // const updateVerificationStatus = async (id: string, status: number) => {
    //     try {
    //         let response = await service.product.updateStatusById(id, { "status": status });
    //         getAllDetail()
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }


    // const handleProductDelete = async (id: string) => {
    //     try {
    //         let response = await service.product.productDelete(id);
    //         if (response.status === 200) {
    //             getAllDetail()
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }

    // }

    useEffect(() => {
        getProductData()
    }, [])

    useEffect(() => {
        setHeader("Product - Approved")
        // setSidebar(false)
        getAllDetail()
    }, [currentPage,typeValue])

    useEffect(() => {
        setCurrentPage(1)
        if (currentPage == 1 && initialLoading) {
            getAllDetail()
        }
        setInitialLoading(true)
    }, [limitPerPage, search, newProductData])

    return (
        <Wrapper>

            <ApproveProduct
                productResponse={productData}
                productDetail={productDetail && productDetail}
                updateVerificationStatus={(id: string, status: number) => { }}
                getDetailById={(id: string) => { getDetailById(id) }}
                setLimitPerPage={setLimitPerPage}
                setCurrentPage={setCurrentPage}
                setSearch={setSearch} currentPage={currentPage} limit={limitPerPage}
                handleProductDelete={''}
                brand={brand}
                productType={productType}
                setProductData={setNewProductData}
                brandResponse={brandResponse}
                setProductType={setProductType}
                getDropdown={getDropdown}
                handleDownload={''}
                isRecognize={typeValue == constant.BRAND_CATEGORY[0].value}
                />
        </Wrapper>
    )

}
export default Index