import React from "react";
import { indexProps } from "./interface"

const Index: React.FC<indexProps> = ({
  printPrescription,
  updateSetting,
}) => {
  return (
    <div className="d-flex">
      <div className="card p-3 w-lg-30">
        <span className="fs-22 fw-600 text-center">Print Prescription</span>
        <span className="fs-16 fw-400 text-muted">If 'Yes' is selected, the lens prescription is shown when creating the invoice. If 'No' is selected, the lens prescription is hidden</span>
        <div className="d-flex gap-5 mt-3 justify-content-center">
          <label
            className="inputs-label fs-20 fw-600"
            htmlFor={"isPictures"}
          >
            <div
              className="form-group d-flex align-items-center ms-1 gap-2"
              style={{ minHeight: "36px" }}
            >
              <input
                className={`form-check-input shadow-none mt-0`}
                type="checkbox"
                id={"isPictures"}
                name={"isPictures"}
                checked={printPrescription}
                onChange={(e) => !printPrescription && updateSetting()}
              />
              <span className={`fs-18 ${printPrescription ? "fw-600" : "fw-400 text-muted"}`}>Yes</span>
            </div>
          </label>
          <label
            className="inputs-label fs-20 fw-600"
            htmlFor={"isPictures"}
          >
            <div
              className="form-group d-flex align-items-center ms-1 gap-2"
              style={{ minHeight: "36px" }}
            >
              <input
                className={`form-check-input shadow-none mt-0`}
                type="checkbox"
                id={"isPictures"}
                name={"isPictures"}
                checked={!printPrescription}
                onChange={(e) => printPrescription && updateSetting()}
              />
              <span className={`fs-18 ${!printPrescription ? "fw-600" : "fw-400 text-muted"}`}>No</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Index;
